@font-face {
  font-family: 'Graphik';
  src: url('/public/fonts/Graphik-Regular.woff2') format('woff2'), url('/public/fonts/Graphik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik-light';
  src: url('/public/fonts/Graphik-Light.woff2') format('woff2'), url('/public/fonts/Graphik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik-bold';
  src: url('/public/fonts/Graphik-Semibold.woff2') format('woff2'), url('/public/fonts/Graphik-Semibold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Monarcha-font';
  src: url('/public/fonts/MonarchaW01-Regular.eot');
  src: url('/public/fonts/MonarchaW01-Regular.eot?#iefix') format('embedded-opentype'),
    url('/public/fonts/MonarchaW01-Regular.woff2') format('woff2'),
    url('/public/fonts/MonarchaW01-Regular.woff') format('woff'),
    url('/public/fonts/MonarchaW01-Regular.ttf') format('truetype'),
    url('/public/fonts/MonarchaW01-Regular.svg#MonarchaW01-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --common-font: 'Graphik';
  --common-font-bd: 'Graphik-bold';
  --common-font-heading: 'Monarcha-font';
}

html body .heading-font {
  font-family: "Khand";
  font-weight: 600 !important;
  font-style: normal;
}

.home-heading-common {
  font-size: 50px;
  letter-spacing: -2px;
  line-height: 55px;
  color: #06356a;

}

@media (max-width:1365px) {
  .home-heading-common {
    font-size: 40px;
    line-height: 45px;
  }
}

@media (max-width:767px) {
  .home-heading-common {
    font-size: 32px;
    line-height: 38px;
  }
}

@media (max-width:576px) {
  .home-heading-common {
    font-size: 30px;
    line-height: 35px;
  }
}



.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--common-font-bd);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

li.nav-item {
  padding: 0 15px;
}

li.nav-item a,
.logout_button_click svg {
  color: #fff;
}



/* 13-01-2022 */
.main-qty-sec .box {
  justify-content: space-between;
}

.price-sec .footer-merge {
  max-width: 380px;
  width: 100%;
}

.fotter-listdetail ul {
  list-style: none;
}

/* 17-01-2021 */


.container.return h1 {
  text-align: center;
}

.container.return {
  padding: 50px 15px;
}

/* 18-05-2022 */
.address_form_field .select::after {
  top: 55%;
}

textarea.form-control {
  box-shadow: none;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.data-commision.css-1ex1afd-MuiTableCell-root {
  text-align: center;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.table-row-data.css-1ygcj2i-MuiTableCell-root {
  text-align: center;
}

.container.order-detail-page.p-4 .table_container>h3 {
  background: var(--blue);
  padding: 10px 20px;
  margin: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

/* 17-08-2022 */

.dialog_scrollClass {
  width: 100%;
  max-width: 100%;
}

.dialog_scrollClass .MuiPaper-root {
  width: 100%;
  border-radius: 0;
}

.dialog_scrollClass .popup button {
  border-radius: 30px;
  border: 2px solid var(--blue);
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}

.dialog_scrollClass .popup {
  display: flex;
  gap: 10px;
}

.Delete_pop p {
  font-size: 24px;
  margin-bottom: 20px;
}

.col-md-4.coupam-enter:empty {
  display: none;
}


body.dashboard :where(.fotter_section, [class*="FooterStyle-sc"]) {
  position: unset !important;
}

body.dashboard .main_div {
  padding: 0;
}

[class*="productPagestyle__ProductPageFooter"] .price_section_footer .price-inner-sec .second_section .custom_radio .title_section p.green_code {
  font-size: 12px;
}

@media (max-width: 1024px) {
  .main_div .bogo-banner {
    top: 112px;
  }
}

@media (max-width: 991px) {
  .extra_footer_section {
    visibility: hidden;
  }

  [class*="productPagestyle__ProductPageFooter"] .price_section_footer .price-inner-sec .second_section .custom_radio .title_section p.green_code {
    bottom: 18px;
  }
}

@media (min-width: 768px) {
  .for_mobile_view {
    display: none;
  }
}

@media (max-width: 767px) {
  .for_desktop_view {
    display: none;
  }

  .row.report_all_in_one [class*="col-md"] {
    padding: 0;
  }

  .address-list .right-side-contents p {
    white-space: break-spaces;
    line-height: normal;
  }

  .table-wrap {
    margin-top: 15px;
  }

  .mainorder-detail-sec {
    margin-top: 0;
  }

  .content_container>* {
    max-width: 100% !important;
    width: 100% !important;
    flex: 100%;
  }

  .order_details_main_dialog .content_container {
    flex-wrap: wrap !important;
  }

  .dashboard_main_div.main_class_responsive .container.order-detail-page.p-4 [class*="couponstyle__C"] {
    padding: 0 20px 20px;
  }

  .dashboard_main_div.main_class_responsive .container.order-detail-page.p-4 [class*="couponstyle__C"] svg.MuiSvgIcon-root {
    margin-right: 0;
  }

  div.mainorder-detail-sec {
    padding: 112px 0 25px !important;
  }

  .Toal-commission span {
    right: 11px;
  }

  .statis_datas .commission-table {
    width: 100%;
  }

  .container.order-detail-page {
    height: auto;
    min-height: auto;
  }

  .dialog_scrollClass p {
    font-size: 16px;
    line-height: normal;
  }

  .commission-table tbody tr:not(:last-child) {
    margin-bottom: 30px;
  }

  .order_table.address-list.order-list.auto-ship-order-table {
    border: none;
  }

  .bogo-banner img {
    display: none;
  }

  .main_div .bogo-banner .for_mob {
    display: block;
  }
}

.bogo-banner {
  position: relative;
  top: 99px;
}

.bogo-banner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.bogo-banner .for_mob {
  display: none;
}


.main_btn_common {
  background-color: #0071e1;
  font-size: 15px;
  color: #fff;
  padding: 12px 30px;
  border-radius: 50px;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
}

.main_btn_common:hover {
  background-color: #034b92;
}

.order_table.order-list .note-mainbx .newrefshow~.newrefshow .referral-copy input[type="text"] {
  max-width: 100% !important;
  width: 100%;
}

.container .main_sign_up_form_class ::placeholder {
  color: #000;
}

.row.cupon_and_kiare_section ::placeholder {
  color: #000;
}

.cart_form ::placeholder {
  color: #000 !important;
}

.select.country-select {
  color: #000;
}

legend.css-1br1jja {
  display: none;
}