.leftsec {
  width: 100%;
}

h3.title1-heading {
  white-space: nowrap;
}

.DownlineStatic {
  width: 100%;
}

.active_smartship_table .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  border-radius: 0;
}

@media (min-width:768px) {
  .mobile_view {
    display: none;
  }
}

@media (max-width: 1024px) {
  .details_price {
    padding-right: 19px;
    text-align: right;
  }

  .col-md-9.statis_datas {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
  }

  .col-md-9.dashboard-main .col-md-3 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }

  .DownlineStatic,
  .leftsec {
    width: 100%;
  }

  .col-md-9.dashboard-main {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .mainorder-detail-sec .col-md-3 {
    max-width: 30%;
    flex: 0 0 30%;
  }

  .col-md-9.statis_datas {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .top-head-commission {
    margin: 30px 0;
  }

  .comission-data-table {
    max-width: 1000px;
    overflow: scroll;
    max-height: 350px;
  }

  .col-md-9.dashboard-main .title+.signupform.main-sign-frm input[type="text"] {
    height: 27px;
    padding: 0 10px;
    border: 1px solid #ddd;
    max-width: 82px !important;
  }

  .col-md-9.dashboard-main .title+.signupform.main-sign-frm button {
    padding: 8px 6px;
    font-size: 14px;
  }

  .commission-report-data-table {
    max-width: 1000px;
    overflow: scroll;
    max-height: 350px;
  }

  .current-rank-left {
    width: 100%;
  }

  .Distributor.row {
    width: 100%;
  }

  .Distributor.row svg {
    width: 100%;
  }

  .mainorder-detail-sec {
    padding: 80px 0 85px;
    width: 100%;
    float: left;
    margin-top: 0;
  }

  .mainorder-detail-sec .col-md-3 {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .left-side ul li {
    padding: 9px 0 9px 26px;
    font-size: 12px;
  }

  .mainorder-detail-sec .container.order-detail-page h3.title,
  .mainorder-detail-sec h3.title {
    font-size: 19px;
    padding: 10px 15px;
  }

  .mainorder-detail-sec .col-md-9 {
    max-width: 78%;
    flex: 0 0 80%;
  }

  .container.order-detail-page .row {
    padding-bottom: 1px;
    margin-bottom: 1px;
  }

  .mainorder-detail-sec .container.order-detail-page button {
    margin-bottom: 2px;
    margin-top: 2px;
  }

  .table-top {
    overflow: auto;
  }

  form.signupform.main-sign-frm {
    width: 1200px;
  }

  .proven_banifits_section_banner {
    margin-top: 65px;
  }

  .collapse.navbar-collapse.hide {
    display: none !important;
  }
}

@media (max-width: 991px) {

  ul.dropdown-menu-wishlist.dropdown-popup_viewCart,
  ul.dropdown-menu-for-cart {
    display: none !important;
  }

  li.wishlist_section.from_fooTer_section:hover ul.dropdown-menu-wishlist.dropdown-popup_viewCart {
    display: block !important;
  }

  li.wishlist_section:hover .dropdown-menu-wishlist {
    width: 100%;
    bottom: 0;
    min-width: 335px;
    left: auto;
    right: 0;
    max-height: 300px;
    overflow: auto;
  }

  li.wishlist_section.from_fooTer_section {
    margin: 22px 22px 0 !important;
  }

  .extra_footer_section ul li {
    display: flex !important;
  }

  .text-welcome h2 {
    color: #fff;
  }

  .product-section-slider {
    clear: both;
  }

  .container_of_slider p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .dashboard_main_div.main_class_responsive .col-md-2 {
    display: none;
  }

  .left-side-part {
    display: none;
  }

  .table-top {
    overflow: visible;
  }
}

@media (max-width: 768px) {
  .details_price {
    padding-right: 19px;
    text-align: right;
  }

  .order_table table th {
    min-width: 93px;
    vertical-align: top;
    vertical-align: top;
  }

  .order_table .commission-table td div {
    display: flex;
  }

  .mainorder-detail-sec .Custom_filter {
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;
  }

  form.signupform.main-sign-frm {
    display: flex !important;
    -webkit-box-align: center;
    align-items: center;
  }


  .date_range_filter {
    font-size: 9px;
    vertical-align: top;
  }

  .order_id_filter {
    font-size: 9px;
  }

  .signupform span {
    font-size: 9px;
  }

  .mainorder-detail-sec .get_commision {
    float: right;
    text-align: right;
    margin-left: 0;
    margin-top: 9px;
  }

  ul.tabs {
    margin: 60px 0 0 !important;
  }

  /* .chart-main {
    width: 100% !important;
  } */

  canvas {
    width: 100%;
  }

  .pro-name {
    font-size: 13px;
  }

  .container.order-detail-page .col-md-2 {
    font-size: 13px;
  }

  /* //sidebar of options */
  .drawer_main_class {
    background: #06356a !important;
    top: 112px !important;
    width: 200px;
  }

  .manu_slider_container ul li .text-left {
    color: #fff;
  }

  .manu_slider_container.MuiBox-root.css-0 ul li {
    padding: 8px 15px;
  }

  .manu_slider_container.MuiBox-root.css-0 ul li i {
    padding-right: 12px;
  }

  .manu_slider_container.MuiBox-root.css-0 li .autoship_second_image,
  .manu_slider_container.MuiBox-root.css-0 li .order_second_image {
    display: none;
  }

  img.order_first_image {
    padding-right: 7px;
    width: 22px;
  }

  img.autoship_first_image {
    padding-right: 7px;
    width: 22px;
  }

  /* .dashboard_main_div.main_class_responsive .col-md-2 {
    display: none;
  } */

  .dashboard_main_div.main_class_responsive .col-md-10 {
    padding: 0 15px !important;
  }

  .container_of_slider .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
    position: relative;
    top: -74px;
    color: #06356a;
  }

  .container_of_slider button svg {
    /* width: 20px; */
    height: 40px;
    font-size: 29px;
    color: #06356a;
  }

  .manu_slider_container li.active {
    background: #fff;
  }

  .manu_slider_container li.active span.icon-lft-now {
    color: #06356a;
  }

  .manu_slider_container li.active span.text-left {
    color: #06356a !important;
  }

  .container_of_slider {
    display: block;
  }

  .manu_slider_container>svg {
    color: #fff;
    font-size: 20px;
    float: right;
    margin: 0 10px;
    margin: -30px 10px 0 0;
  }

  .manu_slider_container.MuiBox-root {
    margin-top: 40px;
  }

  .proven_banifits_section_banner img {
    height: 200px;
    object-fit: cover;
  }

  body .return h2 {
    font-size: 42px;
  }
}

@media (max-width: 767px) {
  .left-side ul li {
    padding: 17px 0 13px 26px;
    font-size: 12px;
  }

  .left-side ul li::before {
    top: 21px;
  }

  .form-subscribe .form-group {
    width: 100%;
  }

  .form-subscribe label {
    font-size: 20px;
  }

  .welcome-section.gauratee-section {
    padding: 40px 0 5px;
  }

  .question-text {
    margin-bottom: 50px;
  }

  .proven_banifits_section_banner {
    margin-top: 57px;
  }

  .proven_banifits_section_banner img {
    height: 130px;
  }

  .mini-cart-sec {
    width: 326px;
  }

  .container.return h1 {
    margin-top: 0;
  }

  .desktop_view {
    display: none;
  }


  .view_cart_popup {
    max-width: calc(100% - 10px);
    max-height: 100%;
    width: 100%;
    margin: 0 auto;
    min-width: auto !important;
  }

  .view_cart_popup .heading {
    font-size: 24px;
    line-height: auto;
  }

  .view_cart_popup .subtitle {
    padding-top: 0;
  }

  .dashboard_main_div {
    min-height: auto;
  }


  .order_details_main_dialog .proven_banifits_section_banner img {
    height: 100px;
    object-position: 60% 100%;
    object-fit: cover;
  }

  .order_details_main_dialog .container.return h1 {
    font-size: 20px;
  }

  .order_details_main_dialog .proven_banifits_section_banner {
    margin-top: 0;
  }

  .order_details_main_dialog .container.return {
    padding: 20px 0px;
  }

  .order_details_main_dialog h3 {
    font-size: 18px;
  }

  .statis_datas {
    margin-top: 20px;
  }

  .mainorder-detail-sec .get_commision {
    float: right;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .change_passsword_from_profile {
    min-width: 93% !important;
  }

  .dialog_scrollClass {
    margin: 0 auto;
  }

  .add_address_class_from_autoship,
  .add_address_from_CheckoutPage {
    min-width: 100% !important;
  }

  .product_add_from_autoship,
  .paymentform_from_savedCardComp {
    min-width: 100%;
  }

  .search.selection.dropdown .menu .item {
    width: 100% !important;
  }

  .welcome-section.gauratee-section .text-welcome {
    transform: translateY(-38px) !important;
  }

  .checkout_main_section_class .container.order-detail-page .row {
    row-gap: 0;
  }

  .row.cupon_and_kiare_section {
    flex-wrap: wrap;
  }

}

@media (max-width: 360px) {
  .add_button_div {
    flex-wrap: wrap;
  }

  .Add_to_wishList {
    margin-top: 10px;
  }
}

@media only screen and (max-width:567px) {
  .welcome-section.gauratee-section .image-welcome iframe {
    min-width: 500px !important;
    min-height: 307px !important;
    height: auto !important;
    line-height: 1;
    margin: 0;
  }
}